<template>
    <div class="PopupBg PopupBgShowTransition" v-press-escape="onCancel" @click="onCancel"></div>
</template>

<script>
export default {
    name: "PopupBg",
    methods: {
        onCancel() {
            this.$emit('cancel');
        }
    }
};
</script>

<style lang="less">
    @import "~styles/const.less";
    .PopupBg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--popup-bg-color);
        z-index: @z-popup-bg;
    }
</style>