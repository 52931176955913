import axios from "axios";
import copy from 'copy-to-clipboard';

export default {
    data() {
        return {

        }
    },
    computed: {
    },
    methods: {
        collectionGetTitle(collection) {
            return collection.isDefault ? 'Default collection' : collection.title;
        },

        collectionGetWords(collection) {
            return (collection.termsCount || 0) + ' word' + (collection.termsCount == 1 ? '' : 's');
        },

        collectionCopyExport(collection) {
            copy(collection.publicUrl, { debug: true, message: 'Press #{key} to copy', });
        },

        collectionChangeArchive(collection, isArchived) {
            return axios.patch(Urls['api:v1:collection:collection-detail']('v1', collection.id), {...collection, isMuted: isArchived});
        },

        collectionDelete(collection) {
            return axios.delete(Urls['api:v1:collection:collection-detail']('v1', collection.id))
        },

        collectionEdit(collection) {
            let req = collection.id
                ? axios.patch(Urls['api:v1:collection:collection-detail']('v1', collection.id), collection)
                : axios.post(Urls['api:v1:collection:collection-list']('v1'), collection);
            return req;
        }
    }
}