<template>
    <ul class="list" @sort-start="$emit('sort-start', $event)" @sort-end="$emit('sort-end', $event)">
      <slot />
    </ul>
</template>

<script>
    import {ContainerMixin, ElementMixin, HandleDirective} from 'vue-slicksort';

    export default {
        mixins: [ContainerMixin],
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";
    ul {
        padding: 0;
        margin: 0;
    }
</style>