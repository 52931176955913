<template>
    <div class="ListEditView">
        <sortable-list :lockAxis="lockAxis" :axis="axis" :value="value" @input="update" :useDragHandle="true" :distance="distance" :helperClass="helperClass"
                        @sort-start="onSortStart" @sort-end="onSortEnd">
            <sortable-item v-for="(item, index) in valueList" :index="index" :key="item.id || item.cid || ('i'+index)" :item="item" v-if="!item.removed">
                <slot :item="item" :index="index" :remove="() => remove(index)" :update="updateOrAppendItem" :setValue="(v) => updateItemIndex(index, v)">
                    {{ item }}
                </slot>
            </sortable-item>
            <slot name="add-inline" :add="() => add()" v-if="hasAddInline">
                <span @click="add()">Add Inline</span>
            </slot>
            <div class="ListEditViewErrors u-ErrorView" v-if="validationList && validationList.$error">
                <div class="error" v-if="validationList.required === false"><slot name="error-required" :error="validationList">List must not be empty.</slot></div>
                <div class="error" v-if="validationList.minLength === false"><slot name="error-minLength" :error="validationList">List must have at least {{ validationList.$params.minLength.min }} elements.</slot></div>
            </div>
        </sortable-list>
        <slot name="add" :add="() => add()" v-if="hasAdd">
            <span @click="add()">{{ addText }}</span>
        </slot>
    </div>
</template>

<script>
    import SortableList from './ListEditViewList.vue';
    import SortableItem from './ListEditViewItem.vue';
    import cloneDeep from 'lodash/cloneDeep';

    export default {
        name: "ListEditView",
        props: {
            value: {type: Array, default: () => []},
            validationList: {type: Object, default: null},
            template: {type: Object, default: () => ({})},
            hasAdd: {type: Boolean, default: true},
            hasAddInline: {type: Boolean, default: false},
            markRemoved: {type: Boolean, default: false},
            lockAxis: {type: String, default: 'y'},
            axis: {type: String, default: 'y'},
            prepend: {type: Boolean, default: false},
            distance: {type: Number, default: 0},
            helperClass: {type: String, default: 'helper'},
            removePrompt: {type: String, default: ''},
            addText: {type: String, default: 'Add'},
        },
        data() {
            return {}
        },
        computed: {
            valueList() {
                console.log('valueList');
                if (this.validationList != null) {
                    return (this.validationList.$each && this.validationList.$each.$iter && Object.values(this.validationList.$each.$iter)) || this.value;
                } else {
                    return this.value
                }
            }
        },
        components: {
            SortableItem,
            SortableList
        },
        mounted() {
            if (this.validationList != null) {
                this.validationList.$touch();
            }
        },
        methods: {
            onSortStart($event) {
                $('html').addClass('dragging');
                this.$emit('sort-start', $event)
            },
            onSortEnd($event) {
                $('html').removeClass('dragging');
                this.$emit('sort-end', $event)
            },

            add(template) {
                this.appendItem(cloneDeep(template || this.template));
            },
            remove(ind) {
                if (this.removePrompt && !confirm(this.removePrompt)) {
                    return;
                }
                let newVal = this.reorder(this.value.filter((v, i) => {
                    return i != ind;
                }));
                if (this.markRemoved) {
                    newVal.push({...this.value[ind], removed: true});
                }
                this.update(newVal);
            },
            update(newVal) {
                this.$emit('input', this.reorder(newVal));
                if (this.validationList != null) {
                    this.validationList.$touch();
                }
            },
            appendItem(item) {
                if (this.prepend) {
                    this.update([item, ...this.value]);
                } else {
                    this.update([...this.value, item]);
                }
            },
            updateOrAppendItem(item) {
                if (this.value.find((v) => v.id && (v.id == item.id))) {
                    this.update(this.value.map(v => (v.id && (v.id == item.id)) ? item : v));
                } else {
                    this.appendItem(item)
                }
            },
            updateItemIndex(index, v) {
                let newVal = [...this.value];
                newVal[index] = v;
                this.update(newVal);
            }
        }
    };
</script>

<style lang="less">
    @import "~styles/const.less";

    .ListEditViewErrors .error {
        color: red;
    }
    .ListEditViewErrors {
        display: none;
    }
    .u-ShowErrors .ListEditViewErrors {
        display: block;
    }

    /*.ListEditView * {
       -moz-user-select: none;
       -khtml-user-select: none;
       -webkit-user-select: none;
       -ms-user-select: none;
       user-select: none;
    }*/

    html.dragging, html.dragging * {
        user-select: none !important;
        cursor: grabbing !important;
    }
</style>

