<template>
    <div class="ImageUploadView" :class="{...modes.map((m) => `ImageUploadView--${m}`), 'u-ErrorView': hasError, ['size-' + size]: true}">
        <div class="ImageUploadViewCaption" v-if="title || caption"><b>{{title}}</b><span>{{caption}}</span></div>
        <div class="ImageUploadViewInner" @dragover.prevent @drop="onDrop" :style="{width, height}">
            <div class="ImageUploadViewInner-wrapper">
                <label :for="cid" class="FullEditView ImageUploadViewLabel"><div class="DefaultHover">Edit cover</div></label>
                <input :id="cid" type="file" name="file" class="ImageUploadViewInput" @change="onSelect">
                <div v-if="imgUrl"  class="ImageUploadViewPreview">
                    <img :src="imgUrl" alt="" :style="{objectFit}"/>
                </div>
                <slot name="defaultView" v-if="!imgUrl">
                    <edit-view></edit-view>
                </slot>
                <progress-bar :options="progressOptions" :value="percentCompleted" v-if="isUploading"/></progress-bar>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ImageUploadView",
        mixins: [mixins.ValidationComponentMixin],
        props: {
            size: { type: String, default: 'medium' },
            apiName: {type: String, default: 'api:v1:temp-file-upload'},
            apiVersion: {type: String, default: 'v1'},
            width: {type: String, default: '192px'},
            height: {type: String, default: '192px'},
            objectFit: {type: String, default: 'cover'},
            tnKey: {type: String, default: 'controlUrl'},
            value: {
                type: Object, default: () => {
                }
            },
            defaultImageUrl: {type: String, default: ''},
            modes: {type: Array, default: () => []},

            title: {type: String, default: ''},
            caption: {type: String, default: ''}
        },
        data: function () {
            return {
                isUploading: false,
                cid: this.generateCid(),
                percentCompleted: 0,
                progressOptions: {
                    text: {
                        hideText: true
                    },
                    progress: {
                        color: '#00aada',
                        backgroundColor: 'white'
                    },
                    layout: {
                        height: 5,
                        width: 140,
                        verticalTextAlign: 61,
                        horizontalTextAlign: 43,
                        zeroOffset: 0,
                        strokeWidth: 30,
                        progressPadding: 0,
                        type: 'line'
                    }
                }
            }
        },
        computed: {
            imgUrl() {
                return (this.innerValue ? (this.innerValue.url || this.innerValue[this.tnKey]) : '') || this.defaultImageUrl;
            }
        },
        methods: {
            onDrop: function (e) {
                e.stopPropagation();
                e.preventDefault();
                this.takeFile(e.dataTransfer.files[0]);
            },
            onSelect(e) {
                this.takeFile(e.target.files[0]);
            },
            takeFile(file) {
                if (!file || !file.type) {
                    return;
                }

                if (!file.type.match('image.*')) {
                    alert('Select an image');
                    return;
                }

                this.uploadFile(file);
            },
            uploadFile(file) {

                this.isUploading = true;
                this.percentCompleted = 0;

                let data = new FormData();
                data.append("file", file);

                let config = {
                    onUploadProgress: (progressEvent) => {
                        this.percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    },
                    headers: {"Content-Type": "multipart/form-data"}
                };

                axios
                    .post(this.apiVersion ? Urls[this.apiName](this.apiVersion) : Urls[this.apiName](), data, config)
                    .then((response) => {
                        this.isUploading = false;
                        this.innerValue = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isUploading = false;
                        alert('Can\' upload file');
                    });
            }
        }
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .ImageUploadView {
        &.size-small {
            width: @card-small;
        }

        &.size-medium {
            width: @card-medium;
        }

        &.size-big {
            width: @card-big;
        }
    }

    .ImageUploadViewInner {
        position: relative;
        border-radius: 14px;
        user-select: none;
        box-shadow: 0px 2px 8px var(--small-outline-shadow-color);
        height: 0;
        padding-top: 100%;

        &-wrapper {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 14px;
            overflow: hidden;
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        }
    }

    .ImageUploadViewLabel {
        position: relative;
        display: block;
        z-index: 1;
        height: 24px;
        margin: 152px auto 0;
        padding: 0 12px;
        width: fit-content;

        border-radius: 99px;
        line-height: 24px;
        text-align: center;
        background: var(--image-upload-button-bg-color);
        color: var(--image-upload-button-color);
        font-weight: bold;
        font-size: 12px;
        box-shadow: 0px 2px 8px var(--small-outline-shadow-color);
    }

    .ImageUploadViewInput {
        display: none;
    }

    .ImageUploadViewPreview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.04);
        }

    }

    .progress-bar {
        position: absolute;
        bottom: 0;
        width: 100% !important;
        z-index: 3;

        /deep/ svg {
            width: 100% !important;
            display: block;
        }
    }

    .ImageUploadViewCaption {
        b {
            display: inline-block;
            font: bold 18px/30px @font;
            letter-spacing: -0.025em;
        }
        span {
            display: inline-block;
            float: right;
            font: 12px/30px @font;
            letter-spacing: 0;
            color: @color-light-text;
            margin-top: 3px;
        }
        margin-bottom: 12px;
    }

    & /deep/ .CollectionImage {
        position: absolute;
        top: 0;
    }

    .u-ShowErrors .ImageUploadView.u-ErrorView .ImageUploadViewInner {
        border: 1px solid red;
        //background: red;
    }
</style>