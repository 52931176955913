var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ListEditView" },
    [
      _c(
        "sortable-list",
        {
          attrs: {
            lockAxis: _vm.lockAxis,
            axis: _vm.axis,
            value: _vm.value,
            useDragHandle: true,
            distance: _vm.distance,
            helperClass: _vm.helperClass
          },
          on: {
            input: _vm.update,
            "sort-start": _vm.onSortStart,
            "sort-end": _vm.onSortEnd
          }
        },
        [
          _vm._l(_vm.valueList, function(item, index) {
            return !item.removed
              ? _c(
                  "sortable-item",
                  {
                    key: item.id || item.cid || "i" + index,
                    attrs: { index: index, item: item }
                  },
                  [
                    _vm._t(
                      "default",
                      [
                        _vm._v(
                          "\n                " + _vm._s(item) + "\n            "
                        )
                      ],
                      {
                        item: item,
                        index: index,
                        remove: function() {
                          return _vm.remove(index)
                        },
                        update: _vm.updateOrAppendItem,
                        setValue: function(v) {
                          return _vm.updateItemIndex(index, v)
                        }
                      }
                    )
                  ],
                  2
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.hasAddInline
            ? _vm._t(
                "add-inline",
                [
                  _c(
                    "span",
                    {
                      on: {
                        click: function($event) {
                          return _vm.add()
                        }
                      }
                    },
                    [_vm._v("Add Inline")]
                  )
                ],
                {
                  add: function() {
                    return _vm.add()
                  }
                }
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.validationList && _vm.validationList.$error
            ? _c("div", { staticClass: "ListEditViewErrors u-ErrorView" }, [
                _vm.validationList.required === false
                  ? _c(
                      "div",
                      { staticClass: "error" },
                      [
                        _vm._t(
                          "error-required",
                          [_vm._v("List must not be empty.")],
                          { error: _vm.validationList }
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.validationList.minLength === false
                  ? _c(
                      "div",
                      { staticClass: "error" },
                      [
                        _vm._t(
                          "error-minLength",
                          [
                            _vm._v(
                              "List must have at least " +
                                _vm._s(
                                  _vm.validationList.$params.minLength.min
                                ) +
                                " elements."
                            )
                          ],
                          { error: _vm.validationList }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasAdd
        ? _vm._t(
            "add",
            [
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.addText))]
              )
            ],
            {
              add: function() {
                return _vm.add()
              }
            }
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }