<template>
    <div class="Login" :class="{'Login--popup': !!onSuccess}">
    <div class="LoginContainer">
        <div class="LoginLogo">
            <svg-view name="logo"></svg-view>
            <span>Word Box</span>
        </div>
        <form class="LoginForm" @submit.prevent="onSubmit" :disabled="true">
            <div class="LoginError" :class="{ 'LoginError--visible': hasError }">{{ error }}</div>

            <input required type="email" v-model="form.email" placeholder="Email" @input="onChange"/>
            <input required type="password" v-model="form.password" placeholder="Password" @input="onChange"/>
            <button :disabled="!submitEnabled">
                Log In
            </button>
        </form>
    </div>
    </div>
</template>

<script>
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import axios from "axios";
import get from "lodash/get";

export default {
    name: "LoginPage",
    props: {
        onSuccess: Function
    },
    data: function() {
        return {
            form: {
                email: '',
                password: ''
            },
            submitEnabled: false,
            error: '',
            hasError: false
        };
    },
    methods: {
        onChange() {
            this.submitEnabled = !isEmpty(this.form.password);
            this.hasError = false;
        },
        onSubmit() {
            axios.post(Urls['api:v1:user:init_login']('v1'), { email: this.form.email, password: this.form.password })
                .then((response) => {
                    if (isFunction(this.onSuccess)) {
                        this.onSuccess()
                    } else {
                        location.reload();
                    }
                })
                .catch((error) => {
                    this.error = get(error, 'response.data.detail') || 'Unknown error, please try later.';
                    this.hasError = true;
                });
        }
    }
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .Login--popup {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background: white;
    }

    .LoginContainer {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 254px;
        transform: translate(-50%, -50%);
    }
    .LoginLogo {
        margin-bottom: 25px;
        svg {
            width:32px;
            height: 32px;
            vertical-align: top;
        }
        span {
            margin-top: 3px;
            margin-left: 5px;
            font-size: 23px;
            display: inline-block;
            font-weight: @bold;
        }
    }
    .LoginForm {
        width: 100%;
        position: relative;
        input {
            background: #F9F9F9;
            font-size: 14px;
            color: black;
            border: 1px solid #E6E6E6;
            display: block;
            width: 100%;
            padding: 10px 16px;
            outline: none;
            margin-bottom: 9px;
            border-radius: 12px;

        }
        button {
            display: block;
            width: 130px;
            height: 40px;
            border-radius: 20px;
            background: @color-active;
            font-size: 16px;
            color: white;
            cursor: pointer;
            margin-top: 20px;
            transition: opacity 0.2s;

            &:disabled {
                background: @color-disabled;
            }
            &:hover:not(:disabled) {
                opacity: 0.7;
            }
        }
    }
    .LoginError {
        position: absolute;
        left: 100%;
        color: #fff;
        top: 45px;
        margin-left: 30px;
        text-align: center;
        font-size: 16px;
        padding: 15px;
        background: #eb1e32;
        border-radius: 12px;
        min-width: 200px;

        transform: none;
        opacity: 0;
        transition: all 0.3s;
        pointer-events: none;

        &--visible {
            transform: translateY(-50%);
            opacity: 1;
            pointer-events: all;
        }
    }
</style>