var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    ref: "textarea",
    style: _vm.computedStyles,
    attrs: { rows: "1" },
    domProps: { value: _vm.value },
    on: {
      focus: _vm.resize,
      keydown: function($event) {
        return _vm.$emit("keydown", $event)
      },
      input: function($event) {
        _vm.resize()
        _vm.$emit("input", $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }