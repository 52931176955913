<template>
    <div class="TextareaView u-InputMarginView" :class="[modes.map((m) => `TextareaView--${m}`), {'u-ErrorView': hasError}]">
        <label class="TextareaViewLabel" :for="`TextareaView-input-${_uid}`" v-if="title">{{title}}</label>
        <textarea-autosize ref="textarea" @keydown="$emit('keydown', $event)" class="TextareaViewInput" :value="innerValue" @input="innerValue = $event.target.value" :id="`TextareaView-input-${_uid}`" :style="{minHeight: minHeight}" :placeholder="placeholder"></textarea-autosize>
    </div>
</template>

<script>
    import TextareaAutosize from './TextareaAutosize.vue';
    export default {
        name: "SectionView",
        mixins: [mixins.ValidationComponentMixin],
        components: {
            TextareaAutosize
        },
        props: {
            title: String,
            value: { type: [Object, String], default: '' },
            minHeight: { type: String, default: '80px' },
            placeholder: { type: String, default: '' },
            modes: { type: Array, default: () => [] }
        },
        methods: {
            setFocus(options) {
                this.$refs.textarea.setFocus(options)
            }
        }
    };
</script>

<style lang="less">
    @import "~styles/const.less";
    .TextareaView {
    }
    .TextareaViewLabel {
        display: block;
        font-family: @font;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.025em;
    }
    .TextareaViewInput {
        display: block;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        outline: none;
        border: 1px solid @color-border-gray;
        padding: 3px 7px 5px;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0;
        margin: 0;
    }
    .TextareaViewLabel + .TextareaViewInput {
        margin: @margin-label-input 0 0;

    }

    .TextareaView--title .TextareaViewInput {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.05em;
        padding: 2px 18px 2px;
    }

    .TextareaView--form .TextareaViewInput {
        padding: 3px 18px 5px;
    }

    .TextareaView--form_company .TextareaViewInput {
        padding: 3px 13px 5px;
    }

    .TextareaView--name .TextareaViewInput {
        font: @semibold 46px/50px @font;
        letter-spacing: -0.05em;
        padding: 23px 17px;
    }
    .TextareaView--caption .TextareaViewInput {
        font: 12px/15px @font;
        letter-spacing: 0;
        padding: 7px 8px 5px;
    }


    .u-ShowErrors .TextareaView.u-ErrorView .TextareaViewInput {
        border-color: red;
    }
</style>