<template>
    <div class="MediumEditor u-InputMarginView" :class="[modes.map((m) => `MediumEditor--${m}`), {'u-ErrorView': hasError}]">
        <label class="MediumEditorLabel" :for="`MediumEditor-input-${_uid}`" v-if="!!title">{{title}}</label>
        <editor class="MediumEditorInput" :id="`MediumEditor-input-${_uid}`" :text="innerValue" :options="options" @edit="checkChange" :style="{minHeight:minHeight}" placeholder="123123"></editor>
    </div>
</template>

<script>
    import editor from 'vue2-medium-editor';
    import rangy from 'rangy';
    import 'rangy/lib/rangy-classapplier';

    rangy.init();

    let HighlighterButton = editor.MediumEditor.extensions.button.extend({
        name: 'highlighter',

        tagNames: ['mark'], // nodeName which indicates the button should be 'active' when isAlreadyApplied() is called
        contentDefault: `<svg class="MediumEditorIcon MediumEditorIcon-highlighter"><use xlink:href="${staticUrl('svg/control/symbol/svg/sprite.symbol.svg#phrase')}"></use></svg>`, // default innerHTML of the button
        contentFA: '<i class="fa fa-paint-brush"></i>', // innerHTML of button when 'fontawesome' is being used
        aria: 'Highlight', // used as both aria-label and title attributes
        action: 'highlight', // used as the data-action attribute of the button

        init: function () {
            editor.MediumEditor.extensions.button.prototype.init.call(this);

            this.classApplier = rangy.createClassApplier('highlight', {
                elementTagName: 'mark',
                normalize: true
            });
        },

        handleClick: function (event) {
            this.classApplier.toggleSelection();
            this.base.checkContentChanged();
            this.button.classList.toggle('medium-editor-button-active', this.classApplier.isAppliedToSelection());
        }
    });

    let allButtons = {
        highlighter: {
            name: 'highlighter',
        },
        bold: {
            name: 'bold',
            contentDefault: `<svg class="MediumEditorIcon MediumEditorIcon-bold"><use xlink:href="${staticUrl('svg/control/symbol/svg/sprite.symbol.svg#bold')}"></use></svg>`
        },
        italic: {
            name: 'italic',
            contentDefault: `<svg class="MediumEditorIcon MediumEditorIcon-italic"><use xlink:href="${staticUrl('svg/control/symbol/svg/sprite.symbol.svg#italic')}"></use></svg>`
        },
        anchor: {
            name: 'anchor',
            contentDefault: `<svg class="MediumEditorIcon MediumEditorIcon-link"><use xlink:href="${staticUrl('svg/control/symbol/svg/sprite.symbol.svg#link')}"></use></svg>`
        },
        unorderedlist: {
            name: 'unorderedlist',
            contentDefault: `<svg class="MediumEditorIcon MediumEditorIcon-ul"><use xlink:href="${staticUrl('svg/control/symbol/svg/sprite.symbol.svg#ul')}"></use></svg>`
        },
        orderedlist: {
            name: 'orderedlist',
            contentDefault: `<svg class="MediumEditorIcon MediumEditorIcon-ol"><use xlink:href="${staticUrl('svg/control/symbol/svg/sprite.symbol.svg#ol')}"></use></svg>`
        }
    };

    let configs = {
        default: ['bold', 'italic', 'anchor', 'unorderedlist', 'orderedlist'],
        termExamples: ['highlighter'],
    };

    export default {
        name: "MediumEditor",
        mixins: [mixins.ValidationComponentMixin],
        components: {
            'editor': editor
        },
        props: {
            title: String,
            value: {type: [Object, String], default: ''},
            minHeight: {type: String, default: '80px'},
            modes: { type: Array, default: () => [] },
            placeholder: {type: [Boolean, String], default: false},
            config: { type: String, default: 'default' },
        },
        computed: {


            options() {
                return {
                    extensions: {
                        'highlighter': new HighlighterButton()
                    },
                    placeholder: this.placeholder ? {text: this.placeholder,  hideOnClick: false } : false,
                    toolbar: {
                        buttons: configs[this.config].map( (k) => allButtons[k] )
                    },
                    anchorPreview: {
                        hideDelay: 1000
                    },
                }
            }
        },
        methods: {
            checkChange($event) {
                let newValue = $event.api.origElements.innerHTML;
                if (!$(`<div>${newValue}</div>`).text()) {
                    newValue = '';
                }
                if (this.innerValue !== newValue) {
                    this.innerValue = newValue;
                }
            }
        }
    };
</script>

<style lang="less">
    @import "~styles/const.less";

    .MediumEditor {
    }

    .MediumEditorLabel {
        display: block;
        //font-family: @UntitledSans;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.025em;
    }

    .MediumEditorInput {
        display: block;
        max-width: 100%;
        min-width: 100%;
        background: white;
        width: 100%;
        outline: none;
        border: 1px solid @color-border-gray;
        padding: 6px 7px 5px;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0;
        margin: 0;
        color: black;

        p {
            margin: 0;
        }

        a {
            color: @color-link;
            text-decoration: none;
        }

        mark {
            background: none;
            color: #F9A932;
        }

        .MediumEditorLabel + & {
            margin: @margin-label-input 0 0;
        }
    }


    .MediumEditorIcon {
        fill: white;
        height: 16px;

        &-highlighter {
            width: 24px;
            height: 14px;
        }
        &-bold {
            width: 10px;
            height: 14px;
        }
        &-italic {
            width: 6px;
        }
        &-link {
            width: 16px;
        }
        &-ul {
            width: 16px;
        }
        &-ol {
            width: 16px;
        }

        .medium-editor-button-active & {
            fill: @color-link;
        }
    }

    .medium-editor-toolbar li button {
        padding: 4px 11px 0;
    }
    .medium-editor-anchor-preview a {
        margin: 5px 5px 7px;
    }

    .medium-editor-placeholder-relative:after, .medium-editor-placeholder:after  {
        margin: 0 !important;
        padding: inherit !important;
        font-style: inherit !important;
        font-weight: inherit !important;
        color: @color-placeholder !important;
    }


    .MediumEditor--subtitle .MediumEditorInput {
        padding: 17px 20px 17px;
        //font-family: @UntitledSans;
    }
    .MediumEditor--form .MediumEditorInput {
        padding: 17px 18px 17px;
        //font-family: @UntitledSans;

    }
    .MediumEditor--title .MediumEditorInput {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.05em;
        padding: 2px 18px 2px;
    }
    .MediumEditor--form_title .MediumEditorInput {
        font-size: 36px;
        line-height: 50px;
        letter-spacing: -0.055em;
        padding: 5px 18px 2px;
    }
    .MediumEditor--form_title_small .MediumEditorInput {
        font-weight: @book;
        font-size: 25px;
        line-height: 40px;
        //font: @book 24px/40px @Campton;
        letter-spacing: -0.050em;
        padding: 4px 17px 2px;
    }
    .u-ShowErrors .MediumEditor.u-ErrorView .MediumEditorInput {
        border-color: red;
    }
    .MediumEditor--footnotes .MediumEditorInput {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0;
        color: #8c959b;
        padding: 26px 18px;
    }
</style>