var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "list",
      on: {
        "sort-start": function($event) {
          return _vm.$emit("sort-start", $event)
        },
        "sort-end": function($event) {
          return _vm.$emit("sort-end", $event)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }