var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InputView u-InputMarginView",
      class: [
        _vm.modes.map(function(m) {
          return "InputView--" + m
        }),
        { "u-ErrorView": _vm.hasError }
      ]
    },
    [
      !!_vm.title
        ? _c(
            "label",
            {
              staticClass: "InputViewLabel",
              attrs: { for: "InputView-input-" + _vm._uid }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue"
              }
            ],
            ref: "input",
            staticClass: "InputViewInput",
            class: { "InputViewInput--transparentCaret": _vm.transparentCaret },
            attrs: {
              id: "InputView-input-" + _vm._uid,
              placeholder: _vm.placeholder,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.innerValue)
                ? _vm._i(_vm.innerValue, null) > -1
                : _vm.innerValue
            },
            on: {
              keydown: _vm.keydown,
              change: function($event) {
                var $$a = _vm.innerValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.innerValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.innerValue = $$c
                }
              }
            }
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue"
              }
            ],
            ref: "input",
            staticClass: "InputViewInput",
            class: { "InputViewInput--transparentCaret": _vm.transparentCaret },
            attrs: {
              id: "InputView-input-" + _vm._uid,
              placeholder: _vm.placeholder,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.innerValue, null) },
            on: {
              keydown: _vm.keydown,
              change: function($event) {
                _vm.innerValue = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue"
              }
            ],
            ref: "input",
            staticClass: "InputViewInput",
            class: { "InputViewInput--transparentCaret": _vm.transparentCaret },
            attrs: {
              id: "InputView-input-" + _vm._uid,
              placeholder: _vm.placeholder,
              type: _vm.type
            },
            domProps: { value: _vm.innerValue },
            on: {
              keydown: _vm.keydown,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.innerValue = $event.target.value
              }
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }