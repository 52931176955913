var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "BlueButton",
      class: { DefaultHover: _vm.defaultHover },
      style: { width: _vm.width, height: _vm.height },
      attrs: { disabled: _vm.disabled }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }