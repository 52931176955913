var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SignupPage", class: { "Signup--popup": !!_vm.onSuccess } },
    [
      _c("div", { staticClass: "SignupContainer" }, [
        _c(
          "div",
          { staticClass: "SignupLogo" },
          [
            _c("svg-view", { attrs: { name: "logo" } }),
            _vm._v(" "),
            _c("span", [_vm._v("Word Box")])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "SignupForm",
            attrs: { disabled: true },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "SignupError",
                class: { "SignupError--visible": _vm.hasError }
              },
              [_vm._v(_vm._s(_vm.error))]
            ),
            _vm._v(" "),
            !_vm.isNewUser
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email"
                    }
                  ],
                  attrs: { required: "", type: "email", placeholder: "Email" },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "email", $event.target.value)
                      },
                      _vm.onChange
                    ]
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isNewUser
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.name,
                      expression: "form.name"
                    }
                  ],
                  attrs: { required: "", type: "name", placeholder: "Name" },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                      _vm.onChange
                    ]
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isNewUser
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.password,
                      expression: "form.password"
                    }
                  ],
                  attrs: {
                    required: "",
                    type: "password",
                    placeholder: "Password"
                  },
                  domProps: { value: _vm.form.password },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "password", $event.target.value)
                      },
                      _vm.onChange
                    ]
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("button", { attrs: { disabled: !_vm.submitEnabled } }, [
              _vm._v("\n            Next\n        ")
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }