<template>
    <div class="InputView u-InputMarginView" :class="[modes.map((m) => `InputView--${m}`), {'u-ErrorView': hasError}]">
        <label class="InputViewLabel" :for="`InputView-input-${_uid}`" v-if="!!title">{{title}}</label>
        <input ref="input" class="InputViewInput" :type="type" v-model="innerValue" :id="`InputView-input-${_uid}`" :placeholder="placeholder"
               :class="{'InputViewInput--transparentCaret': transparentCaret}"
                @keydown="keydown"/>
    </div>
</template>

<script>
export default {
    name: "InputView",
    mixins: [mixins.ValidationComponentMixin],
    props: {
        title: String,
        value: { type: [Object, String], default: '' },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        modes: { type: Array, default: () => [] }
    },
    data() {
        return {
            transparentCaret: false
        }
    },
    methods: {
        setFocus(options) {
            options = options || {};
            this.$refs.input.focus();
            if (options.delayed) {
                this.transparentCaret = true;
                setTimeout(() => {
                    this.transparentCaret = false;
                }, 1000)
            }
        },
        keydown(e) {
            this.$emit('keydown', e);
        }
    }
};
</script>

<style lang="less">
    @import "~styles/const.less";
    .InputView {
    }
    .InputViewLabel {
        display: block;
        font-family: @font;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.025em;
    }
    .InputViewInput {
        display: block;
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid @color-border-gray;
        background: transparent;
        padding: 6px 0 8px 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }
    .InputViewLabel + .InputViewInput {
        margin: @margin-label-input 0 0;
    }

    .u-ShowErrors .InputView.u-ErrorView .InputViewInput {
        border-color: red;
    }

    .InputViewInput--transparentCaret {
        caret-color: transparent;
    }
</style>