import { render, staticRenderFns } from "./SelectView.vue?vue&type=template&id=1434e894&scoped=true&"
import script from "./SelectView.vue?vue&type=script&lang=js&"
export * from "./SelectView.vue?vue&type=script&lang=js&"
import style0 from "./SelectView.vue?vue&type=style&index=0&id=1434e894&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1434e894",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/django/wordbox-test.astroshock.ru/application/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1434e894', component.options)
    } else {
      api.reload('1434e894', component.options)
    }
    module.hot.accept("./SelectView.vue?vue&type=template&id=1434e894&scoped=true&", function () {
      api.rerender('1434e894', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/app/common/utils/components/SelectView.vue"
export default component.exports