<template>
    <div class="SharePage">
        <shared-collection v-if="item" :collection="item" @accept="accept" :accepting="accepting" :accepted="accepted"></shared-collection>
        <share-error v-if="errorData" :errorData="errorData"></share-error>
        <login-page v-if="showLogin" :onSuccess="accept"></login-page>
    </div>
</template>

<script>
    import axios from "axios";
    import get from "lodash/get";
    import SharedCollection from '../components/SharedCollection.vue';
    import ShareError from '../components/ShareError.vue';
    import LoginPage from 'personal/modules/login/Page.vue';


    export default {
        name: "SharePage",
        mixins: [mixins.DocumentTitleMixin],
        components: {SharedCollection, ShareError, LoginPage},
        data: function () {
            return {
                item: null,
                errorData: null,

                accepting: false,
                accepted: false,
                acceptingInterval: null,

                showLogin: false
            };
        },
        computed: {
            documentTitle() {
                return this.makeDocumentTitle('Share');
            },

            code() { return this.$route.params.code },
        },
        mounted() {
            axios.get(Urls['api:v1:share:link']('v1', this.code))
                .then(response => {
                    this.item = response.data;
                    this.errorData = null;
                })
                .catch(error => {
                    this.item = null;
                    this.errorData = get(error, 'response.data') || { detail: 'Can\'t load collection' };
                });
        },
        methods: {
            accept() {
                this.accepting = true;
                this.showLogin = false;
                axios.post(Urls['api:v1:share:accept']('v1', this.code))
                    .then(response => {
                        let taskId = response.data.id;

                        this.acceptingInterval = setInterval(() => {
                            axios.get(Urls['api:v1:share:accept']('v1', taskId))
                                .then(response => {
                                    console.log(response.data);

                                    if (response.data.state == 'FAILURE') {
                                        this.accepting = false;
                                        clearInterval(this.acceptingInterval);
                                        alert('Can\'t add collection')
                                    }

                                    if (response.data.state == 'SUCCESS') {
                                        this.accepting = false;
                                        this.accepted = true;
                                        clearInterval(this.acceptingInterval);
                                        //alert('Success!')
                                    }
                                })
                                .catch(error => {
                                    clearInterval(this.acceptingInterval);
                                    this.accepting = false;
                                    alert(get(error, 'response.data.detail') || 'Can\'t check status of adding')
                                });
                        }, 1000)
                    })
                    .catch(error => {
                        this.accepting = false;
                        if (get(error, 'response.status') == 401) {
                            this.showLogin = true;
                        } else {
                            alert(get(error, 'response.data.detail') || 'Can\'t add collection')
                        }
                    });
            }
        }
    };
</script>


<style scoped lang="less">
    @import "~styles/const.less";

    .ModelPage {
        padding-top: 100px;

        .LeftColumn {
            margin-right: 500px;
        }
        .RightColumn {
            width: 500px;
            background: white;
            border-left: 1px solid #f0f0f0;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
            padding: 40px;
        }
    }

    .Params summary {
        outline: none;
        user-select: none;
    }

    .ParamsList {
        padding-top: 20px;
    }

    .Param {
        min-height: 30px;
        display: flex;
        width: 100%;
        .ParamName {
            width: 200px;
            padding-right: 10px;
        }
        .ParamValue {
            width: 140px;
        }
        .ParamInput {
            flex: 1;
        }
    }
</style>
