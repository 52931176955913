<template>
    <div class="PopupView" ref="rootView" :class="[{show}, modes.map((m) => `PopupView--${m}`)]" :style="{zIndex}" v-if="show">
        <div class="PopupViewContent" :style="{width}">
            <slot name="title">
                <div class="PopupViewTitle">{{title}}</div>
            </slot>
            <slot></slot>
            <div class="PopupViewClose" @click="onClose"><svg-view name="popup_close"></svg-view></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupView",
        props: {
            title: {type: String, default: ''},
            show: {type: Boolean, default: false},
            width: {type: String, default: '998px'},
            zIndex: {type: Number, default: 101},
            onClose: {type: Function, default: () => {} },
            modes: { type: Array, default: () => [] }
        },
        watch: {
            show(newValue, oldValue) {
                if (!oldValue && newValue) {
                    $(this.$refs.rootView).scrollTop(0);
                }
            }
        }
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .PopupView {
        position: fixed;
        z-index: 101;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        padding: 100px 0;
        background: rgba(0,0,0,0.3);
        visibility: hidden;
        &.show {
            visibility: visible;
        }
    }
    .PopupViewContent {
        background: white;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.14);
        padding: 0 158px 100px 160px;
    }
    .PopupViewClose {
        position: absolute;
        top: 0;
        right: 0;
        padding: 23px;
        cursor: pointer;
        svg {
            width: 31px; height: 31px;
            transition: fill 0.2s;
        }
        &:hover  svg{
            fill: @color-link;
        }
    }
    .PopupViewTitle {
        font: @semibold 36px/40px @font;
        padding: 90px 0 67px;
        text-align: center;
        letter-spacing: -0.05em;
        text-transform: uppercase;
    }
    .PopupView--small {
        .PopupViewContent {
            padding: 0 99px 120px 99px;
        }
        .PopupViewTitle {
            font: @bold 18px/30px @font;
            padding: 19px 0 47px;
            letter-spacing: -0.025em;
            text-transform: none;
        }
    }
    .PopupView--small2 {
        .PopupViewContent {
            padding: 0 160px 120px 160px;
        }
        .PopupViewTitle {
            font: @bold 18px/30px @font;
            padding: 24px 0 44px;
            letter-spacing: -0.025em;
            text-transform: none;
        }
    }
</style>