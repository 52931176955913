<template>
    <div class="ShareError">
        ShareError
    </div>
</template>

<script>

    export default {
        name: "ShareError",
        components: {},
        props: {
            errorData: Object
        },
        methods: {
        }
    };
</script>


<style scoped lang="less">
    @import "~styles/const.less";

    .ShareError {
        padding: 100px 50px;
        font-size: 50px;
    }
</style>
