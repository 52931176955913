<template>
    <li class="list-item"><slot /></li>
</template>

<script>
    import {ElementMixin} from 'vue-slicksort';

    export default {
        mixins: [ElementMixin],
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";
    li {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
    }
</style>