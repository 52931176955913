var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SharedCollection" }, [
    _c("div", { staticClass: "Collection" }, [
      _c("div", { staticClass: "CollectionHeader" }, [
        _c("div", {
          staticClass: "CollectionPreview",
          style: {
            backgroundColor: _vm.previewColor,
            backgroundImage: _vm.previewImage
              ? "url(" + _vm.previewImage + ")"
              : "none"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "CollectionTitle" }, [
          _vm._v(_vm._s(_vm.collection.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "CollectionMeta" }, [
          _vm._v(
            _vm._s(_vm.collection.terms.length) +
              " words and phrases · by " +
              _vm._s(_vm.collection.authorName)
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "CollectionTerms" },
        _vm._l(_vm.collection.terms, function(term) {
          return _c("div", { key: term.id, staticClass: "CollectionTerm" }, [
            _c("div", { staticClass: "Term" }, [_vm._v(_vm._s(term.term))]),
            _vm._v(" "),
            _c("div", { staticClass: "Definition" }, [
              _vm._v(_vm._s(term.definition))
            ])
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ButtonWrapper" }, [
      _c(
        "button",
        {
          staticClass: "BlackButton",
          class: { accepted: _vm.accepted },
          attrs: { disabled: _vm.accepting },
          on: {
            click: function() {
              return _vm.$emit("accept")
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.accepted
                ? "Added!"
                : "Add to my collections " +
                    (_vm.accepting ? "(in progress)" : "")
            )
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }