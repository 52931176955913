var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "MediumEditor u-InputMarginView",
      class: [
        _vm.modes.map(function(m) {
          return "MediumEditor--" + m
        }),
        { "u-ErrorView": _vm.hasError }
      ]
    },
    [
      !!_vm.title
        ? _c(
            "label",
            {
              staticClass: "MediumEditorLabel",
              attrs: { for: "MediumEditor-input-" + _vm._uid }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("editor", {
        staticClass: "MediumEditorInput",
        style: { minHeight: _vm.minHeight },
        attrs: {
          id: "MediumEditor-input-" + _vm._uid,
          text: _vm.innerValue,
          options: _vm.options,
          placeholder: "123123"
        },
        on: { edit: _vm.checkChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }