import Vue from 'vue'
import VueRouter from 'vue-router'

//import {Page as Dummy} from './modules/dummy';

import {SharePage as Share} from './modules/share';
import {SignupPage as Signup} from './modules/signup';
import {HomePage as Home} from './modules/home';
import store from './store';

import Urls from 'django_js_front/reverse.js';

Vue.use(VueRouter);

window.app_store = store;

const routes = [
    //{path: Urls['experimental:main'](), redirect: { name: 'model' }, name: 'home'},

    {path: Urls['front:home'](), name: 'home', component: Home, props: true, pathToRegexpOptions: {strict: true}},
    {path: Urls['front:signup'](), name: 'signup', component: Signup, props: true, pathToRegexpOptions: {strict: true}},
    {path: Urls['shortener:link'](':code'), name: 'link', component: Share, props: true, pathToRegexpOptions: {strict: true}},
];

const router = new VueRouter({
    mode: 'history',
    routes: routes
});
window.router = router;

router.afterEach(mixins.DocumentTitleMixin__updateTitle);

export {router, routes};