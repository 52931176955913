<template>
  <textarea
          @focus="resize"
          @keydown="$emit('keydown', $event)"
          :value="value" v-on:input="resize(); $emit('input', $event)"
          :style="computedStyles"
          rows="1"
          ref="textarea"
  ></textarea>
</template>
<script>
    export default {
        name: 'TextareaAutosize',

        mounted() {
            this.resize() // perform initial height adjustment
        },

        props: {
            /*
             * Property to accept value from parent
             */
            value: {
                type: [String, Number],
                default: ''
            },

            /*
             * Allow to enable/disable auto resizing dynamically
             */
            autosize: {
                type: Boolean,
                default: true
            },

            /*
             * Min textarea height
             */
            minHeight: {
                type: [Number],
                'default': null
            },

            /*
             * Max textarea height
             */
            maxHeight: {
                type: [Number],
                'default': null
            },

            /*
             * Force !important for style properties
             */
            important: {
                type: [Boolean, Array],
                default: false
            }
        },

        data() {
            return {
                // works when content height becomes more then value of the maxHeight property
                maxHeightScroll: false
            }
        },

        computed: {
            computedStyles() {
                let objStyles = {};

                if (this.autosize) {
                    objStyles.resize = !this.isResizeImportant ? 'none' : 'none !important'
                    if (!this.maxHeightScroll) {
                        objStyles.overflow = !this.isOverflowImportant ? 'hidden' : 'hidden !important'
                    }
                }

                return objStyles
            },

            isResizeImportant() {
                const imp = this.important
                return imp === true || (Array.isArray(imp) && imp.includes('resize'))
            },

            isOverflowImportant() {
                const imp = this.important
                return imp === true || (Array.isArray(imp) && imp.includes('overflow'))
            },

            isHeightImportant() {
                const imp = this.important
                return imp === true || (Array.isArray(imp) && imp.includes('height'))
            }
        },

        methods: {
          setFocus(options) {
              options = options || {};
              this.$refs.textarea.focus();
              /*if (options.delayed) {
                  this.transparentCaret = true;
                  setTimeout(() => {
                      this.transparentCaret = false;
                  }, 1000)
              }*/
          },
            test() {
                alert(1);
            },
            /*
             * Auto resize textarea by height
             */
            resize: function () {
                //return;
                const important = this.isHeightImportant ? 'important' : ''

                this.$el.style.setProperty('height', 'auto', important)

                let contentHeight = this.$el.scrollHeight + 1

                if (this.minHeight) {
                    contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight
                }

                if (this.maxHeight) {
                    if (contentHeight > this.maxHeight) {
                        contentHeight = this.maxHeight
                        this.maxHeightScroll = true
                    } else {
                        this.maxHeightScroll = false
                    }
                }

                const heightVal = contentHeight + 'px'
                this.$el.style.setProperty('height', heightVal, important)
                return this
            }
        }
    }
</script>