<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "PageWrapper",
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";
</style>