import './urls';

import Vue from 'vue';
import Urls from 'django_js_personal/reverse.js';

import SvgView from './components/SvgView.vue';
import InputView from './components/InputView.vue';
import Switcher from './components/Switcher.vue';
import TextareaView from './components/TextareaView.vue';
import TextareaAutoshrinkFont from './components/TextareaAutoshrinkFont.vue';
import ImageUploadView from './components/ImageUploadView.vue';
import ListEditView from './components/ListEditView/ListEditView.vue';
import PopupView from './components/PopupView.vue';
import PopupBg from './components/PopupBg.vue';
import SelectView from './components/SelectView.vue';
import ColorPicker from './components/ColorPicker.vue';
import WhiteButton from './components/Buttons/WhiteButton.vue';
import EyeButton from './components/Buttons/EyeButton.vue';
import BlueButton from './components/Buttons/BlueButton.vue';
import EditIconButton from './components/Buttons/EditIconButton.vue';
import ExportIconButton from './components/Buttons/ExportIconButton.vue';
import DeleteIconButton from './components/Buttons/DeleteIconButton.vue';
import MenuIconButton from './components/Buttons/MenuIconButton.vue';
import ArchiveIconButton from './components/Buttons/ArchiveIconButton.vue';
import MediumEditor from './components/MediumEditor.vue';
import CheckMark from './components/Buttons/CheckMark.vue';
import VueAppend from 'vue-append';
import {HandleDirective} from 'vue-slicksort';
import pluralize from 'pluralize';

import Datetime from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime);
let cid = 1;
function generateCid() {
    return `cid${++cid}`;
}

Object.defineProperty( Array.prototype, "last", {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function() {
        return this[ this.length - 1 ];
    }
} );

Vue.mixin({
    directives: {sort: HandleDirective},
    components: {
        WhiteButton, BlueButton, EyeButton, EditIconButton, DeleteIconButton, MenuIconButton, ArchiveIconButton, CheckMark, ExportIconButton,
        SvgView, InputView, Switcher, TextareaView, ImageUploadView, ListEditView, PopupView, PopupBg, SelectView, TextareaAutoshrinkFont, ColorPicker, MediumEditor
    },
    methods: {
        static(url) {
            return app.settings.staticUrl + url;
        },
        urlStatic(url) {
            return `url('${app.settings.staticUrl + url}'`;
        },
        generateCid,

        reorder(list) {
            return list.map((item, index) => ({...item, order: index+1}))
        },
        reorderObject(list) {
            return { ...this.reorder(Object.values(list)) }
        },
        sortedTerms(ts) {
            return ts.sort((a,b) => {
                if (a.order != b.order) { return a.order < b.order ? -1 : 1 }
                if (a.id != b.id) { return a.id < b.id ? -1 : 1 }
                return 0
            })
        },
        sortedCollections(cs) {
            return cs.sort((a,b) => {
                if (a.isDefault != b.isDefault) { return -(a.isDefault - b.isDefault) }
                if (a.title != b.title) { return a.title < b.title ? -1 : 1 }
                return 0
            })
        },
        plural(word, count) {
            return pluralize(word, count);
        }
    },
    computed: {
        documentTitle() {
            return this.makeDocumentTitle();
        }
    },
    data: function () {
        return {
            ...(app.settings || {}),
            Urls
        }
    }
});

Vue.use(VueAppend);

Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        setTimeout(function(){
            el.clickOutsideEvent = function (event) {
                // here I check that click was outside the el and his childrens
                if (!(el == event.target || el.contains(event.target))) {
                    // and if it did, call method provided in attribute value
                    vnode.context[binding.expression](event);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent)
        }, 100)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
});

Vue.directive('press-escape', {
    bind: function (el, binding, vnode) {
        setTimeout(function(){
            el.pressEscEvent = function (event) {
                if (event.key === "Escape") {
                    vnode.context[binding.expression](event);
                    event.stopPropagation();
                }
            };
            document.body.addEventListener('keydown', el.pressEscEvent)
        }, 100)
    },
    unbind: function (el) {
        document.body.removeEventListener('keydown', el.pressEscEvent)
    },
});

Vue.directive('document-keypress', {
    bind: function (el, binding, vnode) {
        setTimeout(function(){
            el.pressDocumentKeypressEvent = function (event) {
                vnode.context[binding.expression](event);
            };
            document.body.addEventListener('keydown', el.pressDocumentKeypressEvent)
        }, 100)
    },
    unbind: function (el) {
        document.body.removeEventListener('keydown', el.pressDocumentKeypressEvent)
    },
});

Vue.directive('click-navlinks', {
    bind: function (el, binding, vnode) {
        setTimeout(function(){
            el.clickNavLinksEvent = function (event) {
                if (event.target.closest('a') && event.target.closest('.MainNav')) {
                    vnode.context[binding.expression](event);
                }
            };
            document.body.addEventListener('click', el.clickNavLinksEvent)
        }, 100)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickNavLinksEvent)
    },
});

export function valueGetter(name, defaultValue, resultWrapperFn) {
    return {
        get() {
            return this.value[name] || defaultValue;
        },
        set(value) {
            let res = {...this.value, [name]: value};
            if (typeof resultWrapperFn == 'string') {
                res = this[resultWrapperFn](res);
            }
            this.$emit('input', res)
        }
    }
}

export function timeString(v) {
    let h = (Math.floor(v / 60) + '').padStart(2, "0");
    let m = (v % 60 + '').padStart(2, "0");
    return `${h}:${m}`;
}
export function minutesFromString(v) {
    v = v || '00:00';
    return parseInt(v.split(':')[0])*60 + parseInt(v.split(':')[1]);
}
export function repetitionString(v) {
    return `${v.value} ${repetitionUnitString(v.unit)}`;
}

export function repetitionUnitString(v) {
    switch (v) {
        case 'minutes': return 'мин';
        case 'hours': return 'часов';
        case 'days': return 'дней';
        case 'months': return 'месяцев';
        default: return null;
    }
}
export function repetitionUnitScale(v) {
    switch (v) {
        case 'minutes': return 1;
        case 'hours': return 60;
        case 'days': return 60*24;
        case 'months': return 60*24*30;
        default: return 1;
    }
}

export function splitWords(wordsLine) {
    return wordsLine
        .split(';')
        .map(v => v.trim())
        .filter(v => !!v);
}

export function splitTimeSet(wordsLine) {
    let r1 = wordsLine
        .split(';')
        .map(v => v.trim())
        .filter(v => !!v);
    let r2 = r1
        .filter(v => {
            let s = v.split(':');
            let s1 = parseInt(s[0], 10);
            let s2 = parseInt(s[1], 10);
            return (s.length == 2) && (s1 >= 0) && (s1 <= 24) && (s2 >= 0) && (s2 <= 59);
        });
    return (r1.length == r2.length) && r2;
}


Array.prototype.subarray = function (start, end) {
    if (!end) {
        end = this.length;
    }
    return [...this].slice(start, end);
};


import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';

Vue.use(VTooltip);


export function reCid(list) {
    return list.map(v => ({...v, cid: generateCid()}))
}