var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ImageUploadView",
      class: Object.assign(
        {},
        _vm.modes.map(function(m) {
          return "ImageUploadView--" + m
        }),
        { "u-ErrorView": _vm.hasError },
        ((_obj = {}), (_obj["size-" + _vm.size] = true), _obj)
      )
    },
    [
      _vm.title || _vm.caption
        ? _c("div", { staticClass: "ImageUploadViewCaption" }, [
            _c("b", [_vm._v(_vm._s(_vm.title))]),
            _c("span", [_vm._v(_vm._s(_vm.caption))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ImageUploadViewInner",
          style: { width: _vm.width, height: _vm.height },
          on: {
            dragover: function($event) {
              $event.preventDefault()
            },
            drop: _vm.onDrop
          }
        },
        [
          _c(
            "div",
            { staticClass: "ImageUploadViewInner-wrapper" },
            [
              _c(
                "label",
                {
                  staticClass: "FullEditView ImageUploadViewLabel",
                  attrs: { for: _vm.cid }
                },
                [
                  _c("div", { staticClass: "DefaultHover" }, [
                    _vm._v("Edit cover")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "ImageUploadViewInput",
                attrs: { id: _vm.cid, type: "file", name: "file" },
                on: { change: _vm.onSelect }
              }),
              _vm._v(" "),
              _vm.imgUrl
                ? _c("div", { staticClass: "ImageUploadViewPreview" }, [
                    _c("img", {
                      style: { objectFit: _vm.objectFit },
                      attrs: { src: _vm.imgUrl, alt: "" }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.imgUrl ? _vm._t("defaultView", [_c("edit-view")]) : _vm._e(),
              _vm._v(" "),
              _vm.isUploading
                ? _c("progress-bar", {
                    attrs: {
                      options: _vm.progressOptions,
                      value: _vm.percentCompleted
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }