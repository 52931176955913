<template>
    <button class="ArchiveIconButton">
        <svg-view name="archive"></svg-view>
    </button>
</template>

<script>
export default {
    name: "ArchiveIconButton",
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .ArchiveIconButton {
        position: relative;
        display: block;
        width: 56px;
        height: 56px;
        background: var(--button-bg-color);
        backdrop-filter: blur(27.1828px);
        border-radius: 50%;
        transition: 0.2s background;
        cursor: pointer;

        &:hover {
            background: var(--button-bg-hover-color);

            svg {
                fill: var(--button-icon-hover-color);
            }
        }

        svg {
            position: absolute;
            top: 16px;
            left: 15px;
            width: 26px;
            height: 25px;
            fill: var(--button-icon-color);
            transition: 0.2s fill;
        }
    }
</style>