<template>
    <button class="BlueButton" :class="{'DefaultHover': defaultHover}" :style="{width, height}" :disabled="disabled"><slot></slot></button>
</template>

<script>
export default {
    name: "BlueButton",
    props: {
        width: { type: String, default: '100px' },
        height: { type: String, default: '32px' },
        disabled: {type : Boolean, default: false },
        defaultHover: {type : Boolean, default: true },
    }
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";
    .BlueButton {
        border-radius: 20px;
        background: @color-active;
        color: white;
        font-size: 13px;

        &:disabled {
            background: @color-disabled;
        }
    }
</style>