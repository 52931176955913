<template>
    <button class="EyeButton DefaultHover" :style="{width, height}" :class="{disabled}">
        <svg-view name="eyeopen" v-show="active"></svg-view>
        <svg-view name="eyeshut" v-show="!active"></svg-view>
    </button>
</template>

<script>
export default {
    name: "EyeButton",
    props: {
        width: { type: String, default: '28px' },
        height: { type: String, default: '16px' },
        active: {type : Boolean, default: false },
        disabled: {type : Boolean, default: false }
    }
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";
    .EyeButton {
        font-size: 0;
        position: relative;
        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
        .svg--eyeopen {
            width: 100%;
            height: 100%;
        }
        .svg--eyeshut {
            width: 100%;
            height: 70%;
            top: 30%;
        }

        &.disabled {
            pointer-events: none;
            svg {
                fill: @color-incative;
            }
        }
    }
</style>