var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SharePage" },
    [
      _vm.item
        ? _c("shared-collection", {
            attrs: {
              collection: _vm.item,
              accepting: _vm.accepting,
              accepted: _vm.accepted
            },
            on: { accept: _vm.accept }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.errorData
        ? _c("share-error", { attrs: { errorData: _vm.errorData } })
        : _vm._e(),
      _vm._v(" "),
      _vm.showLogin
        ? _c("login-page", { attrs: { onSuccess: _vm.accept } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }