<template>
    <button class="WhiteButton DefaultHover" :style="{width, height}"><slot></slot></button>
</template>

<script>
export default {
    name: "WhiteButton",
    props: {
        width: { type: String, default: '100px' },
        height: { type: String, default: '32px' },
    }
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";
    .WhiteButton {
        color: @color-incative;
        border: 1px solid @color-incative;
        border-radius: 20px;
        font-size: 13px;
    }
</style>