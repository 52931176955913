var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "SelectView u-InputMarginView",
      class: [
        _vm.modes.map(function(m) {
          return "SelectView--" + m
        }),
        { "u-ErrorView": _vm.hasError }
      ]
    },
    [
      !!_vm.title
        ? _c("label", { staticClass: "SelectViewLabel" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-select", {
        staticClass: "SelectViewInput",
        attrs: {
          value: _vm.selected,
          options: _vm.options,
          placeholder: _vm.placeholder,
          "max-height": _vm.maxHeight
        },
        on: { input: _vm.input },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function(option) {
              return [
                _c("span", {
                  staticClass: "SelectViewOption SelectViewOption--selected",
                  domProps: { innerHTML: _vm._s(option.label) }
                })
              ]
            }
          },
          {
            key: "selected-option",
            fn: function(option) {
              return [
                _c("span", {
                  staticClass: "SelectViewOption SelectViewOption--option",
                  domProps: { innerHTML: _vm._s(option.label) }
                })
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      !!_vm.editOptionsTitle
        ? _c(
            "button",
            { staticClass: "SelectViewEdit", on: { click: _vm.onEditOptions } },
            [_vm._v(_vm._s(_vm.editOptionsTitle))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }