<template>
    <div class="TextareaAutoshrinkFont u-InputMarginView" :class="[modes.map((m) => `TextareaAutoshrinkFont--${m}`), {'u-ErrorView': hasError}, {'TextareaAutoshrinkFont--readonly': readonly}]" :style="{height}">
        <label class="TextareaAutoshrinkFontLabel" :for="`TextareaView-input-${_uid}`" v-if="title">{{title}}</label>
        <textarea ref="textarea" v-model="innerValue" :style="{paddingTop: padding+'px', fontSize: fontSize+'px'}" @keypress="keypress" :readonly="readonly"
                  class="TextareaAutoshrinkFontInput" :id="`TextareaView-input-${_uid}`" :placeholder="placeholder"></textarea>
        <div ref="div" class="TextareaAutoshrinkFontInput TextareaAutoshrinkFontInputSizer" v-html="innerValue"></div>
    </div>
</template>

<script>

    export default {
        name: "TextareaAutoshrinkFont",
        mixins: [mixins.ValidationComponentMixin],
        props: {
            title: String,
            value: {type: [Object, String], default: ''},
            placeholder: {type: String, default: ''},
            modes: {type: Array, default: () => []},
            height: {type: String, default: '200px'},
            noenter: {type: Boolean, default: true},
            readonly: {type: Boolean, default: false}
        },
        data() {
            return {
                padding: 0,
                fontSize: 36,
                prevValue: "$%^"
            }
        },
        methods: {
            setFocus(options) {
                options = options || {};
                this.$refs.textarea.focus();
                /*if (options.delayed) {
                    this.transparentCaret = true;
                    setTimeout(() => {
                        this.transparentCaret = false;
                    }, 1000)
                }*/
            },

            checkHeight() {
                if (this.value === this.prevValue) {
                    return
                }

                this.prevValue = this.value;
                $(this.$refs.div).html(this.value);
                let txH = $(this.$refs.textarea).outerHeight();
                let fs = 36;
                while (fs > 2) {
                    $(this.$refs.div).css('font-size', fs + 'px');
                    if ($(this.$refs.div).outerHeight() < txH) {
                        break;
                    }
                    fs -= 2;
                }

                this.padding = Math.max(0, ($(this.$refs.textarea).outerHeight() - $(this.$refs.div).height()) / 2);
                this.fontSize = fs;
                //console.log([this.padding, this.fontSize, $(this.$refs.div).height()]);
            },

            keypress(evt) {
                if (this.noenter && evt.which === 13) {
                    evt.preventDefault();
                }
            }
        },
        mounted() {
            this.checkHeight()
        },
        watch: {
            value: function (newValue) {
                this.checkHeight()
            }
        }
    };
</script>

<style lang="less">
    @import "~styles/const.less";

    .TextareaAutoshrinkFont {
        position: relative;
        font-size: 18px;
        line-height: 30px;
    }

    .TextareaAutoshrinkFontLabel {
        display: block;
        font-family: @font;
        font-weight: bold;
        letter-spacing: -0.025em;
    }

    .TextareaAutoshrinkFontInput {
        display: block;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        outline: none;
        border: 1px solid @color-border-gray;
        padding: 0;
        font: inherit;
        font-weight: normal;
        line-height: 1.4em;
        letter-spacing: 0;
        margin: 0;
        overflow: hidden;
    }

    textarea.TextareaAutoshrinkFontInput {
        min-height: 100%;
        max-height: 100%;
        height: 100%;
        resize: none;
    }

    div.TextareaAutoshrinkFontInput {
        position: absolute;
        left: 0;
        top: 0;
        color: black;
        word-break: break-word;
        white-space: pre-wrap;
        pointer-events: none;
        opacity: 0.5;
        visibility: hidden;
    }

    .TextareaAutoshrinkFontLabel + .TextareaAutoshrinkFontInput {
        margin: @margin-label-input 0 0;

    }

    .TextareaAutoshrinkFont--cardTerm .TextareaAutoshrinkFontInput {
        text-align: center;
        border: none;
        font-weight: @semibold;
        border-radius: 8px;
    }

    .TextareaAutoshrinkFont--cardTerm.TextareaAutoshrinkFont--readonly .TextareaAutoshrinkFontInput {
        background: transparent;
        color: white;
    }

    .u-ShowErrors .TextareaView.u-ErrorView .TextareaViewInput {
        border-color: red;
    }
</style>