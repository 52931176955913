/*** IMPORTS FROM imports-loader ***/
var me = {};

me.Urls = (function () {

    var Urls = {};

    var self = {
        url_patterns:{}
    };

    var _get_url = function (url_pattern) {
        return function () {
            var _arguments, index, url, url_arg, url_args, _i, _len, _ref,
                _ref_list, match_ref, provided_keys, build_kwargs;

            _arguments = arguments;
            _ref_list = self.url_patterns[url_pattern];

            if (arguments.length == 1 && typeof (arguments[0]) == "object") {
                // kwargs mode
                var provided_keys_list = Object.keys (arguments[0]);
                provided_keys = {};
                for (_i = 0; _i < provided_keys_list.length; _i++)
                    provided_keys[provided_keys_list[_i]] = 1;

                match_ref = function (ref)
                {
                    var _i;

                    // Verify that they have the same number of arguments
                    if (ref[1].length != provided_keys_list.length)
                        return false;

                    for (_i = 0;
                         _i < ref[1].length && ref[1][_i] in provided_keys;
                         _i++);

                    // If for loop completed, we have all keys
                    return _i == ref[1].length;
                }

                build_kwargs = function (keys) {return _arguments[0];}

            } else {
                // args mode
                match_ref = function (ref)
                {
                    return ref[1].length == _arguments.length;
                }

                build_kwargs = function (keys) {
                    var kwargs = {};

                    for (var i = 0; i < keys.length; i++) {
                        kwargs[keys[i]] = _arguments[i];
                    }

                    return kwargs;
                }
            }

            for (_i = 0;
                 _i < _ref_list.length && !match_ref(_ref_list[_i]);
                 _i++);

            // can't find a match
            if (_i == _ref_list.length)
                return null;

            _ref = _ref_list[_i];
            url = _ref[0], url_args = build_kwargs(_ref[1]);
            for (url_arg in url_args) {
            	var url_arg_value = url_args[url_arg];
            	if (url_arg_value === undefined || url_arg_value === null) {
            		url_arg_value = '';
            	} else {
            		url_arg_value = url_arg_value.toString();
            	}
                url = url.replace("%(" + url_arg + ")s", url_arg_value);
            }
            return '/' + url;
        };
    };

    var name, pattern, url, url_patterns, _i, _len, _ref;
    url_patterns = [
        
            [
                'api:v1:collection:collection\u002Ddetail',
                [
                    
                    [
                        'api/%(version)s/collection/%(pk)s.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/collection/%(pk)s/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:collection:collection\u002Ddiscover\u002Dget',
                [
                    
                    [
                        'api/%(version)s/collection/%(pk)s/discover_get.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/collection/%(pk)s/discover_get/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:collection:collection\u002Dlist',
                [
                    
                    [
                        'api/%(version)s/collection.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/collection/',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:collection:collection\u002Dshare',
                [
                    
                    [
                        'api/%(version)s/collection/%(pk)s/share.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/collection/%(pk)s/share/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:collection:collection\u002Dterms',
                [
                    
                    [
                        'api/%(version)s/collection/%(pk)s/terms.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/collection/%(pk)s/terms/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:deep_learning:deep_learning\u002Ddetail',
                [
                    
                    [
                        'api/%(version)s/deep_learning/%(pk)s.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/deep_learning/%(pk)s/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:discover:discover\u002Ddetail',
                [
                    
                    [
                        'api/%(version)s/discover/%(pk)s.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/discover/%(pk)s/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:discover:discover\u002Dfolders',
                [
                    
                    [
                        'api/%(version)s/discover/%(pk)s/folders.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/discover/%(pk)s/folders/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:discover:discover\u002Dlist',
                [
                    
                    [
                        'api/%(version)s/discover.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/discover/',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:folder:folder\u002Dcollections',
                [
                    
                    [
                        'api/%(version)s/folder/%(pk)s/collections.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/folder/%(pk)s/collections/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:folder:folder\u002Ddetail',
                [
                    
                    [
                        'api/%(version)s/folder/%(pk)s.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/folder/%(pk)s/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:folder:folder\u002Dfolders',
                [
                    
                    [
                        'api/%(version)s/folder/%(pk)s/folders.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/folder/%(pk)s/folders/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:folder:folder\u002Ditems',
                [
                    
                    [
                        'api/%(version)s/folder/%(pk)s/items.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/folder/%(pk)s/items/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:folder:folder\u002Dlist',
                [
                    
                    [
                        'api/%(version)s/folder.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/folder/',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:share:accept',
                [
                    
                    [
                        'api/%(version)s/accept/%(code)s.%(format)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/accept/%(code)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:share:link',
                [
                    
                    [
                        'api/%(version)s/s/%(code)s.%(format)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/s/%(code)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:temp\u002Dfile\u002Dupload',
                [
                    
                    [
                        'api/%(version)s/upload.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/upload',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:term:term\u002Ddetail',
                [
                    
                    [
                        'api/%(version)s/term/%(pk)s.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/term/%(pk)s/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:term:term\u002Dlist',
                [
                    
                    [
                        'api/%(version)s/term.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/term/',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:term:term\u002Dmove',
                [
                    
                    [
                        'api/%(version)s/term/%(pk)s/move.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/term/%(pk)s/move/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:term:term\u002Dview',
                [
                    
                    [
                        'api/%(version)s/%(id)s/view.%(format)s',
                        [
                            
                            'version',
                            
                            'id',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/%(id)s/view',
                        [
                            
                            'version',
                            
                            'id',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:test',
                [
                    
                    [
                        'api/%(version)s/test.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/test/',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:test2',
                [
                    
                    [
                        'api/%(version)s/test2.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/test2/',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:translation',
                [
                    
                    [
                        'api/%(version)s/translation.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/translation/',
                        [
                            
                            'version',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/translation/%(code)s.%(format)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/translation/%(code)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:confirm_email',
                [
                    
                    [
                        'api/%(version)s/user/confirm_email.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/confirm_email',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:exists',
                [
                    
                    [
                        'api/%(version)s/user/exists.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/exists',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:init',
                [
                    
                    [
                        'api/%(version)s/user/init.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/init',
                        [
                            
                            'version',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/get_session_cookie/%(user_id)s.%(format)s',
                        [
                            
                            'version',
                            
                            'user_id',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/get_session_cookie/%(user_id)s/',
                        [
                            
                            'version',
                            
                            'user_id',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:init_login',
                [
                    
                    [
                        'api/%(version)s/user/init_login.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/init_login',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:init_register',
                [
                    
                    [
                        'api/%(version)s/user/init_register.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/init_register',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:learn_plan',
                [
                    
                    [
                        'api/%(version)s/user/learn_plan.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/learn_plan',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:login',
                [
                    
                    [
                        'api/%(version)s/user/login.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/login',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:logout',
                [
                    
                    [
                        'api/%(version)s/user/logout.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/logout',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:notification',
                [
                    
                    [
                        'api/%(version)s/user/notification.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/notification',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:register',
                [
                    
                    [
                        'api/%(version)s/user/register.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/register',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:resend_email_confirm',
                [
                    
                    [
                        'api/%(version)s/user/resend_email_confirm.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/resend_email_confirm',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:reset_password',
                [
                    
                    [
                        'api/%(version)s/user/reset_password.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/reset_password',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:search',
                [
                    
                    [
                        'api/%(version)s/user/search.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user/search',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:user',
                [
                    
                    [
                        'api/%(version)s/user.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user',
                        [
                            
                            'version',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:user:user\u002Ddetail',
                [
                    
                    [
                        'api/%(version)s/user%(pk)s.%(format)s',
                        [
                            
                            'version',
                            
                            'pk',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/user%(pk)s/',
                        [
                            
                            'version',
                            
                            'pk',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'api:v1:voice',
                [
                    
                    [
                        'api/%(version)s/voice.%(format)s',
                        [
                            
                            'version',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/voice/',
                        [
                            
                            'version',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/voice/%(code)s.%(format)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                            'format',
                            
                        ],
                    ],
                    
                    [
                        'api/%(version)s/voice/%(code)s',
                        [
                            
                            'version',
                            
                            'code',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'personal:collection',
                [
                    
                    [
                        'personal/collections/%(_0)s/',
                        [
                            
                            '_0',
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'personal:collections',
                [
                    
                    [
                        'personal/collections/',
                        [
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'personal:discover',
                [
                    
                    [
                        'personal/discover/',
                        [
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'personal:logout',
                [
                    
                    [
                        'personal/logout/',
                        [
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'personal:main',
                [
                    
                    [
                        'personal/',
                        [
                            
                        ]
                    ]
                    
                ],
            ],
        
            [
                'personal:settings',
                [
                    
                    [
                        'personal/settings/',
                        [
                            
                        ]
                    ]
                    
                ]
            ]
        
    ];

    self.url_patterns = {};
    for (_i = 0, _len = url_patterns.length; _i < _len; _i++) {
        _ref = url_patterns[_i], name = _ref[0], pattern = _ref[1];
        self.url_patterns[name] = pattern;
        url = _get_url(name);
        Urls[name] = url;
        Urls[name.replace(/-/g, '_')] = url;
    }

    return Urls;
})();


/*** EXPORTS FROM exports-loader ***/
module.exports = me.Urls;
