<template>
    <div class="SelectView u-InputMarginView" :class="[modes.map((m) => `SelectView--${m}`), {'u-ErrorView': hasError}]">
        <label class="SelectViewLabel" v-if="!!title">{{title}}</label>
        <v-select class="SelectViewInput" :value="selected" v-on:input="input" :options="options" :placeholder="placeholder" :max-height="maxHeight">

            <template slot="option" slot-scope="option">
                <span class="SelectViewOption SelectViewOption--selected" v-html="option.label"></span>
            </template>
            <template slot="selected-option" slot-scope="option">
                <span class="SelectViewOption SelectViewOption--option" v-html="option.label"></span>
            </template>
        </v-select>
        <button class="SelectViewEdit" v-if="!!editOptionsTitle" @click="onEditOptions">{{editOptionsTitle}}</button>
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    import isObject from 'lodash/isObject';

    export default {
        name: "SelectView",
        mixins: [mixins.ValidationComponentMixin],
        components: {
            vSelect
        },
        props: {
            title: {type: String, default: ''},
            editOptionsTitle: {type: String, default: ''},
            onEditOptions: {
                type: Function, default: () => {
                }
            },
            value: {type: [Object, Number, String], default: ''},
            options: {type: Array, default: () => []},
            placeholder: {type: String, default: ''},
            modes: {type: Array, default: () => []},
            maxHeight: {type: String, default: '400px'}
        },
        computed: {
            selected() {
                return this.options.find((v) => v.id == this.innerValue)
            }
        },
        mounted() {
            this.value && this.value.$touch && this.value.$touch();
        },
        methods: {
            input($event) {
                this.innerValue = isObject($event) ? $event.id : $event;
            }
        }
    };
</script>

<style scoped lang="less">
    @import "~styles/const.less";

    .SelectView {
        position: relative;
    }

    .SelectViewLabel {
        display: block;
        font-family: @font;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.025em;
    }

    .SelectViewInput {

        /deep/ .clear {
            display: none !important;
        }

        /deep/ .dropdown-toggle {
            border: 1px solid @color-border-gray;
            border-radius: 0;
            padding: 1px 4px 7px;
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0;
            font-family: @font;
        }
        /deep/ .dropdown-menu {
            overflow: auto !important;
            padding: 0;
            border-radius: 0;
        }
        /deep/ .dropdown-menu li[role=option] {
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0;
            font-family: @font;
            a {
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
            }
        }
        /deep/ .open-indicator:before {
            width: 0;
            height: 0;
            border: 4px solid;
            //border-bottom: none;
            //border-left: none;
            border-color: black transparent transparent transparent;
            position: relative;
            top: -2px;
            transform: none !important;
        }
        /deep/ .open-indicator {
            display: inline-block;
            padding: 0;
            margin: 14px 5px 0 0;
            width: 8px;
            height: 8px;
        }
        &.open /deep/ .open-indicator {
            transform: rotate(180deg);

        }

        /deep/ .no-options {
            font-weight: normal;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: 0;
            font-family: @font;
            color: @color-light-text;
        }
        /*
                /deep/ .selected-tag {
                    max-width: calc(100% - 20px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    //display: inline-block;
                }
        */

        /deep/ .selected-tag {
            max-width: calc(100% - 20px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
        }
        /deep/ .vs__selected-options {
            max-width: calc(100% - 22px);
        }

    }

    .SelectViewLabel + .SelectViewInput {
        margin: @margin-label-input 0 0;
    }

    .SelectViewEdit {
        position: absolute;
        top: 3px;
        right: 0;
        font: 12px/30px @font;
        color: @color-link;
        transition: opacity 0.2s;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .SelectViewOption {
        pointer-events: none;
    }

    .u-ShowErrors .SelectView.u-ErrorView .SelectViewInput /deep/ .dropdown-toggle{
        border-color: red !important;

    }
</style>