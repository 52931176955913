var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "TextareaAutoshrinkFont u-InputMarginView",
      class: [
        _vm.modes.map(function(m) {
          return "TextareaAutoshrinkFont--" + m
        }),
        { "u-ErrorView": _vm.hasError },
        { "TextareaAutoshrinkFont--readonly": _vm.readonly }
      ],
      style: { height: _vm.height }
    },
    [
      _vm.title
        ? _c(
            "label",
            {
              staticClass: "TextareaAutoshrinkFontLabel",
              attrs: { for: "TextareaView-input-" + _vm._uid }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.innerValue,
            expression: "innerValue"
          }
        ],
        ref: "textarea",
        staticClass: "TextareaAutoshrinkFontInput",
        style: {
          paddingTop: _vm.padding + "px",
          fontSize: _vm.fontSize + "px"
        },
        attrs: {
          readonly: _vm.readonly,
          id: "TextareaView-input-" + _vm._uid,
          placeholder: _vm.placeholder
        },
        domProps: { value: _vm.innerValue },
        on: {
          keypress: _vm.keypress,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.innerValue = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", {
        ref: "div",
        staticClass:
          "TextareaAutoshrinkFontInput TextareaAutoshrinkFontInputSizer",
        domProps: { innerHTML: _vm._s(_vm.innerValue) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }