<template>
    <div class="ColorPicker u-InputMarginView" :class="[modes.map((m) => `ColorPicker--${m}`), {'u-ErrorView': hasError}]">
        <label class="ColorPickerLabel" v-if="!!title">{{title}}</label>
        <div class="ColorPickerPreview" :style="{backgroundColor: editingColor, width: previewWidth, height: previewHeight}"
             @click="show"></div>
        <sketch class="ColorPickerEditor" v-if="showed" v-click-outside="hide" v-model="editingColor" :disable-alpha="true"></sketch>
    </div>
</template>

<script>
import { Sketch } from 'vue-color';

export default {
    name: "ColorPicker",
    mixins: [mixins.ValidationComponentMixin],
    components: {Sketch},
    props: {
        title: String,
        value: { type: String, default: null }, //hex
        defaultValue: { type: String, default: '#ffffff' },
        modes: { type: Array, default: () => [] },
        previewWidth: { type: String, default: '100%' },
        previewHeight: { type: String, default: '30px' },
    },
    data() {
        return {
            showed: false
        }
    },
    computed: {
        editingColor: {
            get() {
                let v = this.innerValue || this.defaultValue;
                return (v.startsWith('#') ? '' : '#') + v
            },
            set(newVal) { return this.innerValue = newVal.hex },
        }
    },
    methods: {
        show() {
            this.showed = true
        },
        hide() {
            this.showed = false
        }
    }
};
</script>

<style lang="less">
    @import "~styles/const.less";
    .ColorPicker {
        position: relative;
    }
    .ColorPickerLabel {
        display: block;
        font-family: @font;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.025em;
    }
    .ColorPickerPreview {
        display: block;
        border: 1px solid @color-border-gray;
        cursor: pointer;
    }
    .ColorPickerLabel + .ColorPickerInput {
        margin: @margin-label-input 0 0;
    }
    .ColorPickerEditor {
        position: absolute !important;
        top: 100%;
        left: 0;
        z-index: 2;
    }

    .u-ShowErrors .ColorPicker.u-ErrorView .ColorPickerInput {
        border-color: red;
    }

    .ColorPickerInput--transparentCaret {
        caret-color: transparent;
    }
</style>