<template>
    <div class="HomePage">
        Word box app
    </div>
</template>

<script>
    export default {
        name: "SharePage",
        mixins: [mixins.DocumentTitleMixin],
        data: function () {
            return {
            };
        },
        computed: {
            documentTitle() {
                return this.makeDocumentTitle('Home');
            }
        },
        mounted() {
        },
        methods: {
        }
    };
</script>


<style scoped lang="less">
    @import "~styles/const.less";
</style>
