var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "CheckMark",
      style: { width: _vm.width, height: _vm.height }
    },
    [
      _c("svg-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selected,
            expression: "selected"
          }
        ],
        attrs: { name: "checkmark-selected" }
      }),
      _vm._v(" "),
      _c("svg-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.selected,
            expression: "!selected"
          }
        ],
        attrs: { name: "checkmark-unselected" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }