var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          ref: "rootView",
          staticClass: "PopupView",
          class: [
            { show: _vm.show },
            _vm.modes.map(function(m) {
              return "PopupView--" + m
            })
          ],
          style: { zIndex: _vm.zIndex }
        },
        [
          _c(
            "div",
            { staticClass: "PopupViewContent", style: { width: _vm.width } },
            [
              _vm._t("title", [
                _c("div", { staticClass: "PopupViewTitle" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "PopupViewClose", on: { click: _vm.onClose } },
                [_c("svg-view", { attrs: { name: "popup_close" } })],
                1
              )
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }