var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { isDisabled: _vm.disabled, Switcher: true } }, [
    _c("label", { staticClass: "Switcher-label" }, [
      _vm._v(_vm._s(_vm.title) + "\n        "),
      !_vm.disabled
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue"
              }
            ],
            staticClass: "Switcher-input",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.innerValue)
                ? _vm._i(_vm.innerValue, null) > -1
                : _vm.innerValue
            },
            on: {
              change: function($event) {
                var $$a = _vm.innerValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.innerValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.innerValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.innerValue = $$c
                }
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "Switcher-control" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }