var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "TextareaView u-InputMarginView",
      class: [
        _vm.modes.map(function(m) {
          return "TextareaView--" + m
        }),
        { "u-ErrorView": _vm.hasError }
      ]
    },
    [
      _vm.title
        ? _c(
            "label",
            {
              staticClass: "TextareaViewLabel",
              attrs: { for: "TextareaView-input-" + _vm._uid }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("textarea-autosize", {
        ref: "textarea",
        staticClass: "TextareaViewInput",
        style: { minHeight: _vm.minHeight },
        attrs: {
          value: _vm.innerValue,
          id: "TextareaView-input-" + _vm._uid,
          placeholder: _vm.placeholder
        },
        on: {
          keydown: function($event) {
            return _vm.$emit("keydown", $event)
          },
          input: function($event) {
            _vm.innerValue = $event.target.value
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }