var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ColorPicker u-InputMarginView",
      class: [
        _vm.modes.map(function(m) {
          return "ColorPicker--" + m
        }),
        { "u-ErrorView": _vm.hasError }
      ]
    },
    [
      !!_vm.title
        ? _c("label", { staticClass: "ColorPickerLabel" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "ColorPickerPreview",
        style: {
          backgroundColor: _vm.editingColor,
          width: _vm.previewWidth,
          height: _vm.previewHeight
        },
        on: { click: _vm.show }
      }),
      _vm._v(" "),
      _vm.showed
        ? _c("sketch", {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.hide,
                expression: "hide"
              }
            ],
            staticClass: "ColorPickerEditor",
            attrs: { "disable-alpha": true },
            model: {
              value: _vm.editingColor,
              callback: function($$v) {
                _vm.editingColor = $$v
              },
              expression: "editingColor"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }