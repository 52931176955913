<template>
    <div class="SharedCollection">
        <div class="Collection">
            <div class="CollectionHeader">
                <div class="CollectionPreview" :style="{backgroundColor: previewColor, backgroundImage: previewImage ? `url(${ previewImage })` : 'none'}"></div>
                <div class="CollectionTitle">{{collection.title}}</div>
                <div class="CollectionMeta">{{collection.terms.length}} words and phrases · by {{collection.authorName}}</div>
            </div>
            <div class="CollectionTerms">
                <div class="CollectionTerm" v-for="term in collection.terms" :key="term.id">
                    <div class="Term">{{term.term}}</div>
                    <div class="Definition">{{term.definition}}</div>
                </div>
            </div>
        </div>
        <div class="ButtonWrapper">
            <button class="BlackButton" @click="() => $emit('accept')" :disabled="accepting" :class="{accepted}">{{accepted ? 'Added!' : ('Add to my collections ' + (accepting ? '(in progress)' : ''))}}</button>
        </div>
    </div>
</template>

<script>

    import get from 'lodash/get';

    export default {
        name: "SharedCollection",
        components: {},
        props: {
            collection: Object,
            accepting: Boolean,
            accepted: Boolean,
        },
        computed: {
            previewImage() {
                return get(this.collection, 'coverListImage.webUrl') || get(this.collection, 'coverDiscoverImage.webUrl')
            },
            previewColor() {
                return get(this.collection, 'coverListColor') || get(this.collection, 'coverDiscoverColor')
            }
        },
        methods: {
        }
    };
</script>


<style scoped lang="less">
    @import "~styles/const.less";

    @width: 448px;

    .SharedCollection {
        background: #F8F8F8;
        height: 100%;
        padding: 32px 0 0;
    }

    .Collection {
        max-width: @width;
        margin: 0 auto;

        min-height: calc(100vh - 32px);
        background: white;
        padding-bottom: 162px;
        border-radius: 12px 12px 0 0;
    }

    .CollectionHeader {
        text-align: center;
        padding-top: 32px;
        padding-bottom: 16px;
    }

    .CollectionPreview {
        width: 144px;
        height: 144px;
        margin: 0 auto 20px;
        background-size: cover;
        border-radius: 12px;
        background-color: gray;
    }

    .CollectionTitle {
        font-size: 32px;
        line-height: 34px;
        font-weight: bold;
        margin: 10px 30px;
    }

    .CollectionMeta {
        font-size: 14px;
        line-height: 26px;
        margin: 10px 30px;
        opacity: 0.45;
    }

    .CollectionTerms {
    }

    .CollectionTerm {
        display: flex;

        /*&:hover {
            background: yellow;
        }*/

        &+.CollectionTerm {
            border-top: 1px solid #F1F1F1;
        }
    }

    .Term, .Definition {
        width: 50%;
        font-size: 15px;
        line-height: 20px;
    }
    .Term {
        padding: 17px 16px 19px 32px;
    }
    .Definition {
        padding: 17px 32px 19px 16px;
        opacity: 0.4;
    }

    .ButtonWrapper {
        position: fixed;
        width: 448px;
        left: 50%;
        margin-left: calc(-@width/2);
        bottom: 0;
        padding: 0 16px 16px;

        &:before {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: -98px;
            background: rgba(255,255,255,0);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            pointer-events: none;
            z-index: -1;
        }
    }

    .BlackButton {
        display: block;
        background: black;
        width: 100%;
        color: white;
        font-size: 14px;
        line-height: 28px;
        font-weight: bold;
        padding: 11px 0 9px;
        border-radius: 8px;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        &:hover {
            opacity: 0.7;
        }
        &:disabled {
            opacity: 0.5;
        }
        &.accepted {
            pointer-events: none;
        }
    }
</style>
