var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "press-escape",
        rawName: "v-press-escape",
        value: _vm.onCancel,
        expression: "onCancel"
      }
    ],
    staticClass: "PopupBg PopupBgShowTransition",
    on: { click: _vm.onCancel }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }