import { render, staticRenderFns } from "./PopupBg.vue?vue&type=template&id=7fda794e&"
import script from "./PopupBg.vue?vue&type=script&lang=js&"
export * from "./PopupBg.vue?vue&type=script&lang=js&"
import style0 from "./PopupBg.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/django/wordbox-test.astroshock.ru/application/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('7fda794e', component.options)
    } else {
      api.reload('7fda794e', component.options)
    }
    module.hot.accept("./PopupBg.vue?vue&type=template&id=7fda794e&", function () {
      api.rerender('7fda794e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/app/common/utils/components/PopupBg.vue"
export default component.exports