<template>
    <div :class="{'isDisabled': disabled, 'Switcher': true}">
        <label class="Switcher-label">{{ title }}
            <input v-if="!disabled" class="Switcher-input" type="checkbox" v-model="innerValue"/>
            <div class="Switcher-control"></div>
        </label>
    </div>
</template>

<script>
export default {
    name: "Switcher",
    mixins: [mixins.ValidationComponentMixin],
    props: {
        title: String,
        disabled: { type: Boolean, default: false },
        value: { type: Boolean, default: false }
    }
};
</script>

<style lang="less">
    @import "~styles/const.less";
    .Switcher {

        &.isDisabled {
            pointer-events: none;
            opacity: 0.5;
        }

        &-label {
            display: inline-block;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            font-size: 20px;
            line-height: 24px;
            color: var(--switcher-text-color);
            padding-left: 48px;
        }

        &-input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        &-control {
            position: absolute;
            top: 2px;
            left: 0;
            width: 36px;
            height: 20px;
            border-radius: 99px;
            background-color: var(--switcher-bg-unchecked-color);
            transition: 0.2s background-color;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 2px;
                left: 2px;
                width: 16px;
                height: 16px;
                background-color: var(--switcher-handle-color);
                border-radius: 99px;
                transition: 0.2s transform;
            }
        }

        &-input:checked ~ &-control {
            background-color: var(--switcher-bg-checked-color);
        }

        &-input:checked ~ &-control:after {
            transform: translateX(16px);
        }
    }

</style>