<template>
    <button class="CheckMark" :style="{width, height}">
        <svg-view name="checkmark-selected" v-show="selected"></svg-view>
        <svg-view name="checkmark-unselected" v-show="!selected"></svg-view>
    </button>
</template>

<script>
export default {
    name: "EyeButton",
    props: {
        width: { type: String, default: '18px' },
        height: { type: String, default: '18px' },
        selected: {type : Boolean, default: false }
    }
};
</script>

<style scoped lang="less">
    @import "~styles/const.less";
    .CheckMark {
        font-size: 0;
        position: relative;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }
    }
</style>